body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

html {
  width: 100%;
  height: 100%;
}

.hover-green:hover {
  color: green;
}

.hover-pink:hover {
  color: pink;
}
